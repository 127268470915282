import { styled } from '@auth0/quantum-product';
import * as React from 'react';
var LogoSvg = styled('svg')(function () { return ({
    verticalAlign: 'middle',
    width: '64px',
    height: 'auto',
}); });
export var DevDayLogo = function () { return (React.createElement(LogoSvg, { width: "63", height: "18", viewBox: "0 0 63 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { "clip-path": "url(#clip0_482_1232)" },
        React.createElement("path", { d: "M7.24083 6.60444V2H9.15753V14.4444H7.48929L7.25859 13.0756C6.67293 13.8756 5.73234 14.5511 4.29482 14.5511C1.82796 14.5511 0 12.8089 0 9.89333C0 7.08444 1.82796 5.23556 4.29482 5.23556C5.67909 5.23556 6.65519 5.80444 7.24083 6.60444ZM4.61425 12.9333C6.2115 12.9333 7.25859 11.6889 7.25859 9.92889C7.25859 8.11556 6.2115 6.87111 4.61425 6.87111C3.01702 6.87111 1.96993 8.11556 1.96993 9.89333C1.96993 11.6889 3.01702 12.9333 4.61425 12.9333Z", fill: "white" }),
        React.createElement("path", { d: "M14.7219 14.5511C11.8647 14.5511 10.0722 12.7022 10.0722 9.91111C10.0722 7.10223 11.8647 5.23556 14.5445 5.23556C17.1711 5.23556 18.9281 6.90667 18.9813 9.57333C18.9813 9.80444 18.9636 10.0533 18.9281 10.3022H12.0776V10.4267C12.1309 11.9733 13.107 12.9867 14.6155 12.9867C15.7868 12.9867 16.6386 12.4 16.9049 11.3867H18.8215C18.5021 13.1822 16.9759 14.5511 14.7219 14.5511ZM12.1486 8.89777H16.9936C16.8338 7.54667 15.8933 6.78223 14.5622 6.78223C13.3377 6.78223 12.2905 7.6 12.1486 8.89777Z", fill: "white" }),
        React.createElement("path", { d: "M22.3588 14.4444L19.0046 5.34223H21.0632L23.5656 12.3822L26.0325 5.34223H28.0556L24.7014 14.4444H22.3588Z", fill: "white" }),
        React.createElement("path", { d: "M41.7431 6.60444V2H43.6598V14.4444H41.9915L41.7608 13.0756C41.1752 13.8756 40.2346 14.5511 38.797 14.5511C36.3302 14.5511 34.5023 12.8089 34.5023 9.89333C34.5023 7.08444 36.3302 5.23556 38.797 5.23556C40.1814 5.23556 41.1574 5.80444 41.7431 6.60444ZM39.1165 12.9333C40.7137 12.9333 41.7608 11.6889 41.7608 9.92889C41.7608 8.11556 40.7137 6.87111 39.1165 6.87111C37.5193 6.87111 36.4722 8.11556 36.4722 9.89333C36.4722 11.6889 37.5193 12.9333 39.1165 12.9333Z", fill: "white" }),
        React.createElement("path", { d: "M53.306 12.7733H53.6965V14.4444H52.685C51.4425 14.4444 51.0167 13.8933 51.0167 12.9867C50.4133 13.9111 49.4905 14.5511 48.0174 14.5511C46.0297 14.5511 44.6454 13.5733 44.6454 11.8844C44.6454 10.0178 45.9942 8.96889 48.5321 8.96889H50.8215V8.41777C50.8215 7.40444 50.0939 6.78223 48.8338 6.78223C47.698 6.78223 46.9349 7.31556 46.7928 8.11556H44.9117C45.1069 6.33777 46.6331 5.23556 48.9225 5.23556C51.3361 5.23556 52.7203 6.39111 52.7203 8.54223V12.1867C52.7203 12.6489 52.9156 12.7733 53.306 12.7733ZM50.8215 10.7111V10.3911H48.4433C47.2543 10.3911 46.5976 10.8356 46.5976 11.76C46.5976 12.5244 47.2365 13.0578 48.2836 13.0578C49.8808 13.0578 50.8036 12.1156 50.8215 10.7111Z", fill: "white" }),
        React.createElement("path", { d: "M56.6309 14.4977L53.0991 5.34223H55.1402L57.6779 12.2578L60.1448 5.34223H62.1857L58.0684 15.8667C57.5005 17.3423 57.1101 18 55.7791 18H53.7203V16.3111H55.0691C55.8144 16.3111 55.992 16.1333 56.2759 15.3867L56.6309 14.4977Z", fill: "white" }),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M33.8828 14.4444H27.2291V13.3064H33.8828V14.4444Z", fill: "white" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_482_1232" },
            React.createElement("rect", { width: "62.2925", height: "16", fill: "white", transform: "translate(0 2)" }))))); };

import { styled } from '@auth0/quantum-product';
import * as React from 'react';
var BackgroundSvg = styled('div')({
    position: 'absolute',
    minWidth: '1200px',
    width: '100%',
    height: '100%',
    minHeight: '60px',
    top: 0,
    left: 0,
    zIndex: -1,
    '& svg': {
        width: '100%',
        height: '100%',
    },
});
export var DevDayBg = function () { return (React.createElement(BackgroundSvg, null,
    React.createElement("svg", { fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_371_18641)" },
            React.createElement("rect", { width: "1440", height: "60", fill: "#242424" }),
            React.createElement("path", { d: "M1238.49 -37.9445L1184.6 81.4866C1182.69 85.7274 1177.36 87.1349 1173.6 84.3922L1113.67 40.6528C1110.83 38.5795 1109.86 34.7805 1111.36 31.6009L1167.15 -86.4023C1169.11 -90.5592 1174.37 -91.901 1178.09 -89.1924L1236.13 -46.8811C1238.93 -44.8371 1239.92 -41.1071 1238.49 -37.9445Z", fill: "url(#paint0_radial_371_18641)", "fill-opacity": "0.2" }),
            React.createElement("path", { d: "M1238.14 -38.1018L1184.25 81.3294C1182.44 85.3488 1177.39 86.683 1173.83 84.0832L1113.9 40.3439C1111.2 38.3788 1110.29 34.778 1111.71 31.7643L1167.49 -86.2388C1169.35 -90.1788 1174.34 -91.4505 1177.86 -88.8833L1235.9 -46.572C1238.56 -44.6347 1239.49 -41.0994 1238.14 -38.1018Z", stroke: "url(#paint1_radial_371_18641)", "stroke-width": "0.76499" }),
            React.createElement("path", { d: "M1206.74 -35.9125L1147.14 75.3868C1144.64 80.0556 1138.16 80.613 1134.9 76.4399L1084.06 11.3762C1082.17 8.96696 1081.98 5.64185 1083.58 3.03461L1148.29 -102.581C1150.97 -106.96 1157.21 -107.293 1160.35 -103.224L1206.08 -43.8443C1207.84 -41.5595 1208.1 -38.455 1206.74 -35.9125Z", fill: "url(#paint2_radial_371_18641)", "fill-opacity": "0.2" }),
            React.createElement("path", { d: "M1206.4 -36.0931L1146.8 75.2062C1144.43 79.6315 1138.29 80.1596 1135.2 76.2044L1084.36 11.1406C1082.57 8.8571 1082.39 5.70568 1083.91 3.23452L1148.62 -102.381C1151.16 -106.532 1157.07 -106.848 1160.04 -102.991L1205.78 -43.6109C1207.44 -41.4454 1207.69 -38.5028 1206.4 -36.0931Z", stroke: "url(#paint3_radial_371_18641)", "stroke-width": "0.76499" }),
            React.createElement("path", { d: "M1174.96 -31.4917L1106.87 71.4448C1103.7 76.2436 1096.48 75.6807 1094.09 70.4475L1052.86 -19.7871C1051.7 -22.3263 1052.08 -25.3042 1053.85 -27.4671L1126.55 -116.544C1129.94 -120.704 1136.51 -119.921 1138.83 -115.079L1175.46 -38.7041C1176.57 -36.3801 1176.38 -33.6414 1174.96 -31.4917Z", fill: "url(#paint4_radial_371_18641)", "fill-opacity": "0.2" }),
            React.createElement("path", { d: "M1174.64 -31.7028L1106.55 71.2339C1103.54 75.7822 1096.71 75.2485 1094.44 70.2885L1053.21 -19.946C1052.11 -22.3527 1052.47 -25.1752 1054.14 -27.2252L1126.84 -116.302C1130.06 -120.245 1136.29 -119.503 1138.49 -114.913L1175.11 -38.5388C1176.17 -36.336 1175.99 -33.7403 1174.64 -31.7028Z", stroke: "url(#paint5_radial_371_18641)", "stroke-width": "0.76499" }),
            React.createElement("path", { d: "M1141.52 -27.3877L1068.77 61.4908C1064.99 66.1018 1057.58 64.52 1056.02 58.7694L1027.87 -44.8796C1027.2 -47.3588 1027.87 -50.0096 1029.65 -51.8661L1103.96 -129.572C1107.87 -133.659 1114.74 -131.966 1116.3 -126.532L1142.9 -34.0555C1143.56 -31.7424 1143.05 -29.2501 1141.52 -27.3877Z", fill: "url(#paint6_radial_371_18641)", "fill-opacity": "0.2" }),
            React.createElement("path", { d: "M1141.23 -27.63L1068.47 61.2485C1064.89 65.6189 1057.87 64.1196 1056.39 58.6691L1028.24 -44.98C1027.6 -47.3297 1028.24 -49.8422 1029.92 -51.6017L1104.24 -129.307C1107.94 -133.181 1114.46 -131.577 1115.94 -126.426L1142.53 -33.9498C1143.16 -31.7574 1142.67 -29.3952 1141.23 -27.63Z", stroke: "url(#paint7_radial_371_18641)", "stroke-width": "0.76499" }),
            React.createElement("path", { d: "M1107.45 -24.1964L1030.85 50.7792C1026.55 54.9897 1019.29 52.5066 1018.46 46.5439L1003.85 -59.3143C1003.53 -61.6228 1004.33 -63.9453 1006 -65.5701L1081.23 -138.748C1085.51 -142.902 1092.68 -140.495 1093.59 -134.606L1109.56 -30.5458C1109.92 -28.2118 1109.13 -25.8482 1107.45 -24.1964Z", fill: "url(#paint8_radial_371_18641)", "fill-opacity": "0.2" }),
            React.createElement("path", { d: "M1107.18 -24.4697L1030.58 50.5058C1026.51 54.4966 1019.62 52.1432 1018.84 46.4916L1004.23 -59.3666C1003.93 -61.5547 1004.68 -63.7559 1006.27 -65.2958L1081.5 -138.473C1085.55 -142.411 1092.35 -140.129 1093.21 -134.548L1109.18 -30.4877C1109.52 -28.2756 1108.78 -26.0353 1107.18 -24.4697Z", stroke: "url(#paint9_radial_371_18641)", "stroke-width": "0.76499" }),
            React.createElement("path", { d: "M1071.26 -19.9295L994.876 40.1406C990.266 43.766 983.477 40.7777 983.037 34.9298L974.43 -79.4758C974.243 -81.9571 975.328 -84.3636 977.311 -85.8666L1057.81 -146.879C1062.54 -150.458 1069.33 -147.244 1069.56 -141.323L1074.05 -25.9753C1074.14 -23.629 1073.1 -21.381 1071.26 -19.9295Z", fill: "url(#paint10_radial_371_18641)", "fill-opacity": "0.2" }),
            React.createElement("path", { d: "M1071.02 -20.2302L994.639 39.84C990.271 43.2761 983.836 40.4439 983.418 34.9011L974.811 -79.5045C974.634 -81.8563 975.663 -84.1372 977.541 -85.5618L1058.04 -146.574C1062.52 -149.966 1068.96 -146.92 1069.18 -141.308L1073.67 -25.9604C1073.76 -23.7366 1072.77 -21.6059 1071.02 -20.2302Z", stroke: "url(#paint11_radial_371_18641)", "stroke-width": "0.76499" }),
            React.createElement("path", { d: "M1032.81 -15.5177L957.707 23.7879C952.694 26.4117 946.725 22.606 946.988 16.9537L952.665 -104.969C952.786 -107.574 954.282 -109.918 956.595 -111.125L1035.41 -152.273C1040.51 -154.934 1046.55 -150.955 1046.11 -145.221L1036.72 -21.4563C1036.53 -18.9349 1035.05 -16.6903 1032.81 -15.5177Z", fill: "url(#paint12_radial_371_18641)", "fill-opacity": "0.2" }),
            React.createElement("path", { d: "M1032.63 -15.8566L957.53 23.4491C952.778 25.9359 947.121 22.3287 947.369 16.9715L953.047 -104.951C953.162 -107.42 954.581 -109.642 956.772 -110.786L1035.59 -151.934C1040.42 -154.456 1046.14 -150.685 1045.73 -145.25L1036.34 -21.4852C1036.15 -19.0954 1034.75 -16.968 1032.63 -15.8566Z", stroke: "url(#paint13_radial_371_18641)", "stroke-width": "0.76499" }),
            React.createElement("path", { d: "M995.32 -12.6976L920.038 6.45669C914.936 7.75473 910.17 3.41125 910.99 -1.78844L930.848 -127.607C931.295 -130.437 933.349 -132.747 936.108 -133.521L1013.02 -155.104C1018.19 -156.555 1023.12 -152.134 1022.23 -146.836L1000.74 -18.5891C1000.26 -15.7246 998.134 -13.4139 995.32 -12.6976Z", fill: "url(#paint14_radial_371_18641)", "fill-opacity": "0.2" }),
            React.createElement("path", { d: "M995.225 -13.0684L919.942 6.08608C915.108 7.31627 910.59 3.19954 911.369 -1.72875L931.226 -127.548C931.65 -130.23 933.596 -132.419 936.211 -133.153L1013.13 -154.735C1018.03 -156.111 1022.69 -151.92 1021.85 -146.899L1000.36 -18.6523C999.908 -15.9373 997.894 -13.7472 995.225 -13.0684Z", stroke: "url(#paint15_radial_371_18641)", "stroke-width": "0.76499" })),
        React.createElement("defs", null,
            React.createElement("radialGradient", { id: "paint0_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1110.08 73.6348) rotate(-85.0762) scale(166.866 315.733)" },
                React.createElement("stop", { offset: "0.0694667", "stop-color": "#F6F1E7" }),
                React.createElement("stop", { offset: "0.30241", "stop-color": "#F2AC84" }),
                React.createElement("stop", { offset: "0.522187", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.684258", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.897972", "stop-color": "#191919" })),
            React.createElement("radialGradient", { id: "paint1_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1207.65 37.915) rotate(-103.982) scale(121.104 70.9988)" },
                React.createElement("stop", { "stop-color": "#191919" }),
                React.createElement("stop", { offset: "0.2", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.333333", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.526042", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.703125", "stop-color": "#F6F1E7", "stop-opacity": "0" })),
            React.createElement("radialGradient", { id: "paint2_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1073.95 71.1029) rotate(-84.539) scale(185.169 337.72)" },
                React.createElement("stop", { offset: "0.0694667", "stop-color": "#F6F1E7" }),
                React.createElement("stop", { offset: "0.30241", "stop-color": "#F2AC84" }),
                React.createElement("stop", { offset: "0.522187", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.684258", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.897972", "stop-color": "#191919" })),
            React.createElement("radialGradient", { id: "paint3_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1179.02 30.208) rotate(-102.917) scale(133.126 76.6627)" },
                React.createElement("stop", { "stop-color": "#191919" }),
                React.createElement("stop", { offset: "0.2", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.333333", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.526042", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.703125", "stop-color": "#F6F1E7", "stop-opacity": "0" })),
            React.createElement("radialGradient", { id: "paint4_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1035.07 70.2115) rotate(-84.5669) scale(202.248 369.588)" },
                React.createElement("stop", { offset: "0.0694667", "stop-color": "#F6F1E7" }),
                React.createElement("stop", { offset: "0.30241", "stop-color": "#F2AC84" }),
                React.createElement("stop", { offset: "0.522187", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.684258", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.897972", "stop-color": "#191919" })),
            React.createElement("radialGradient", { id: "paint5_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1150.01 25.6159) rotate(-102.973) scale(145.475 83.8562)" },
                React.createElement("stop", { "stop-color": "#191919" }),
                React.createElement("stop", { offset: "0.2", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.333333", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.526042", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.703125", "stop-color": "#F6F1E7", "stop-opacity": "0" })),
            React.createElement("radialGradient", { id: "paint6_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1004.71 63.88) rotate(-84.1126) scale(210.795 372.931)" },
                React.createElement("stop", { offset: "0.0694667", "stop-color": "#F6F1E7" }),
                React.createElement("stop", { offset: "0.4375", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.645833", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.897972", "stop-color": "#191919" })),
            React.createElement("radialGradient", { id: "paint7_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1116.03 18.8881) rotate(-99.8589) scale(152.059 85.8626)" },
                React.createElement("stop", { "stop-color": "#191919" }),
                React.createElement("stop", { offset: "0.2", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.333333", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.526042", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.703125", "stop-color": "#F6F1E7", "stop-opacity": "0" })),
            React.createElement("radialGradient", { id: "paint8_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(978.283 56.886) rotate(-83.4427) scale(214.293 360.544)" },
                React.createElement("stop", { offset: "0.0694667", "stop-color": "#F6F1E7" }),
                React.createElement("stop", { offset: "0.4375", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.645833", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.897972", "stop-color": "#191919" })),
            React.createElement("radialGradient", { id: "paint9_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1087 9.42513) rotate(-98.5459) scale(153.114 83.8072)" },
                React.createElement("stop", { "stop-color": "#191919" }),
                React.createElement("stop", { offset: "0.2", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.333333", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.526042", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.828125", "stop-color": "#F6F1E7", "stop-opacity": "0" })),
            React.createElement("radialGradient", { id: "paint10_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(946.071 45.9437) rotate(-83.6624) scale(213.662 365.581)" },
                React.createElement("stop", { offset: "0.0694667", "stop-color": "#F6F1E7" }),
                React.createElement("stop", { offset: "0.30241", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.522187", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.684258", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.897972", "stop-color": "#191919" })),
            React.createElement("radialGradient", { id: "paint11_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1055.93 -0.814976) rotate(-98.9794) scale(153.136 84.715)" },
                React.createElement("stop", { "stop-color": "#191919" }),
                React.createElement("stop", { offset: "0.2", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.333333", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.526042", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.78125", "stop-color": "#F6F1E7", "stop-opacity": "0" })),
            React.createElement("radialGradient", { id: "paint12_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(920.47 30.6389) rotate(-83.5916) scale(203.895 346.996)" },
                React.createElement("stop", { offset: "0.0694667", "stop-color": "#F6F1E7" }),
                React.createElement("stop", { offset: "0.30241", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.522187", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.684258", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.897972", "stop-color": "#191919" })),
            React.createElement("radialGradient", { id: "paint13_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(1020.17 -13.4272) rotate(-96.9799) scale(146.066 80.7257)" },
                React.createElement("stop", { "stop-color": "#191919" }),
                React.createElement("stop", { offset: "0.2", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.333333", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.526042", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.796875", "stop-color": "#F6F1E7", "stop-opacity": "0" })),
            React.createElement("radialGradient", { id: "paint14_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(896.269 13.8164) rotate(-83.8171) scale(189.496 328.027)" },
                React.createElement("stop", { offset: "0.0694667", "stop-color": "#F6F1E7" }),
                React.createElement("stop", { offset: "0.30241", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "0.522187", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.684258", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.897972", "stop-color": "#191919" })),
            React.createElement("radialGradient", { id: "paint15_radial_371_18641", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(987.975 -27.0392) rotate(-96.4951) scale(135.465 75.0872)" },
                React.createElement("stop", { "stop-color": "#191919" }),
                React.createElement("stop", { offset: "0.2", "stop-color": "#4016A0" }),
                React.createElement("stop", { offset: "0.333333", "stop-color": "#3F59E4" }),
                React.createElement("stop", { offset: "0.526042", "stop-color": "#4CB7A3" }),
                React.createElement("stop", { offset: "1", "stop-color": "#F6F1E7", "stop-opacity": "0" })),
            React.createElement("clipPath", { id: "clip0_371_18641" },
                React.createElement("rect", { width: "1440", height: "60", fill: "white" })))))); };

import { DevDayBg } from '@a0/docs-components/ui/DevDayBg';
import { DevDayLogo as BannerLogo } from '@a0/docs-components/ui/DevDayLogo';
import { Button, ExternalLinkIcon, Link, styled, Text } from '@auth0/quantum-product';
import React from 'react';
var BannerWrapper = styled('div')(function (_a) {
    var isVisible = _a.isVisible;
    return ({
        display: isVisible ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#242424',
        width: '100vw',
        color: 'white',
        margin: '0px auto',
        position: 'fixed',
        height: '60px',
        zIndex: 20,
        top: 0,
    });
});
var ContentWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '96%',
    maxWidth: '1440px',
    position: 'relative',
    '@media (max-width: 959px)': {
        width: '100%',
    },
});
var LeftAlignedContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '@media (max-width: 959px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0px',
    },
});
var CloseButton = styled(Button)({
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '22px',
    cursor: 'pointer',
    marginLeft: 'auto',
});
var DesktopBanner = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '@media (max-width: 959px)': {
        display: 'none',
    },
});
var MobileBanner = styled('div')({
    display: 'none',
    flexDirection: 'column',
    padding: '12px 24px',
    '@media (max-width: 959px)': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
    },
});
export var DevDayBanner = function (_a) {
    var isVisible = _a.isVisible, onClose = _a.onClose;
    return (React.createElement(BannerWrapper, { isVisible: isVisible },
        React.createElement(DesktopBanner, null,
            React.createElement(DevDayBg, null),
            React.createElement(ContentWrapper, null,
                React.createElement(LeftAlignedContent, null,
                    React.createElement(BannerLogo, null),
                    React.createElement(Text, { variant: "body1", sx: { lineHeight: '1', paddingLeft: '8px' } }, "Join Developer Day on Sep 24, 2024, to unleash the power of Auth0."),
                    React.createElement(Link, { variant: "body1", target: "_blank", rel: "noreferrer", href: "https://a0.to/devday2024/auth0docs", sx: [
                            { color: '#99A7F1', fontWeight: '500', lineHeight: '1' },
                            {
                                '&:hover': {
                                    color: '#99A7F1',
                                    textDecoration: 'none',
                                },
                            },
                        ], endIcon: React.createElement(ExternalLinkIcon, { sx: { fontSize: '18px' } }) }, "Reserve your spot")),
                React.createElement(CloseButton, { onClick: onClose }, "\u00D7"))),
        React.createElement(MobileBanner, null,
            React.createElement(ContentWrapper, null,
                React.createElement(LeftAlignedContent, null,
                    React.createElement(Text, { variant: "body1" }, "Join Developer Day on Sep 24, 2024"),
                    React.createElement(Link, { variant: "body1", target: "_blank", rel: "noreferrer", href: "https://a0.to/devday2024/auth0docs", sx: [
                            { color: '#99A7F1', fontWeight: '500', lineHeight: '1' },
                            {
                                '&:hover': {
                                    color: '#99A7F1',
                                    textDecoration: 'none',
                                },
                            },
                        ], endIcon: React.createElement(ExternalLinkIcon, { sx: { fontSize: '18px' } }) }, "Reserve your spot")),
                React.createElement(CloseButton, { sx: { minWidth: '30px' }, onClick: onClose }, "\u00D7")))));
};
